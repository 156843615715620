type GetValidityProps = {
  companyType: string;
  path: string;
  auth: any;
};

export enum CompanyTypes {
  ADMIN = "ADMINISTRATION",
  GSSA = "GSSA",
  FORWARDER = "FORWARDER",
}

export enum UserTypes {
  MASTER = "MASTER",
  AWB_ONLY = "AWB Only"
}

const COMMON_ROUTES = ["/quotes", "/shipments", "/rates"];

const ADMIN = [
  "/admin",
  "/admins",
  "/fedagsa-members",
  "/terms-and-conditions",
  "/shipments",
  "/ssim",
  "/ssim/:id",
  "/flight-details/:ssimHdrId",
  "/gha-members",
  "/searches",
  "/abws",
  "/logos"
];

const GSSA = [
  ...COMMON_ROUTES,
  "/templates",
  "/ssim",
  "/flight-details/:ssimHdrId",
  "/flights",
  "/manage-account",
];

const FORWARDER = [
  "/quotes",
  "/shipments",
  // ...COMMON_ROUTES,
  "/getQuote",
  "/listofrates",
  "/comparison",
];

export const checkValidity = (companyType, path, userid: GetValidityProps) => {
  if (userid) {
    if (companyType === CompanyTypes.ADMIN) {
      return ADMIN.includes(path);
    } else if (companyType === CompanyTypes.GSSA) {
      return GSSA.includes(path);
    } else if (companyType === CompanyTypes.FORWARDER) {
      return FORWARDER.includes(path);
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const CommanHeaders = [
  { path: "/quotes", header: "Quotes" },
  { path: "/shipments", header: "Shipments" },
  { path: "/rates", header: "Rates" },
];

const AdminHeaders = [
    { path: "/abws", header: "AWBs" },
    { path: "/logos", header: "Logos" },
];
const MasterAdminHeaders = [
  { path: "/admin", header: "Users" },
  { path: "/admins", header: "Administrators" },
  { path: "/shipments", header: "Accounts" },
  { path: "/fedagsa-members", header: "Fedagsa Members" },
  { path: "/gha-members", header: "GHAs" },
  { path: "/searches", header: "Searches" },
  { path: "/abws", header: "AWBs" },
  { path: "/logos", header: "Logos" },
  // { path: "/terms-and-conditions", header: "T&C’s" },
];

const GssaHeaders = [
  ...CommanHeaders,
  { path: "/flights", header: "Flights" },
  { path: "/manage-account", header: "Account Management" },
];

const GssaReservationAgentHeaders = [
  ...CommanHeaders,
  { path: "/flights", header: "Flights" },
];

const ForwarderHeaders = [
  { path: "/getQuote", header: "Search" },
  { path: "/quotes", header: "Quotes" },
  { path: "/shipments", header: "Shipments" },
  // { path: "/rates", header: "Rates" },
];

export const getUserHeaders = (
  companyType: any,
  userType: any,
  userLevel: any
) => {
  if (companyType === CompanyTypes.ADMIN) {
    if (userType === UserTypes.MASTER) {
      return MasterAdminHeaders;
    } else {
      return AdminHeaders;
    }
  } else if (companyType === CompanyTypes.GSSA) {
    if (userLevel === "1") {
      return GssaReservationAgentHeaders;
    } else {
      return GssaHeaders;
    }
  } else if (companyType === CompanyTypes.FORWARDER) {
    return ForwarderHeaders;
  } else {
    return [];
  }
};

export default checkValidity;
